import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';

export interface ErrorData {
  httpCode: number;
  specificMessageCode?: string;
  additionalErrorCode?: string;
}

@Injectable()
export class ErrorPopupService {
  private showErrorPopSubject = new Subject<ErrorData>();

  constructor() {}

  testMethod() {
    //console.log('Error Pop up serv');
  }

  showPopup(errorData: ErrorData) {
    this.showErrorPopSubject.next(errorData);
  }

  showErrorPopUp(): Observable<any> {
    return this.showErrorPopSubject.asObservable();
  }
}
