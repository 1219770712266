/**
 * Created by karthikeyan.k on 06-02-2019.
 */

import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { ErrorPopupService } from '../services/error.popup.service';
import { UserService } from '../services/user.service';
import { UtilService } from '../util/UtilService';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    public errorPopupService: ErrorPopupService,
    public userService: UserService,
    public utilService: UtilService
  ) {
    this.errorPopupService.testMethod();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let status = '';
    let statuscode = '';
    const rngDomain = this.utilService.rngServerURL;

    if (('' + request.url).includes('DetailedGameHistory')) {
    } else {
      if (
        this.userService.user.sessiontoken != undefined &&
        this.userService.user.sessiontoken != ''
      ) {
        request = request.clone({
          setHeaders: {
            sessiontoken: this.userService.user.sessiontoken,
            ...(isSamePageOrRng(request.url, rngDomain)
              ? getAuthHeaders()
              : {}),
          },
        });
      }
    }
    // requirement for session end popup
    /*
SESSION_SLEEP_TIME 15 min
SESSION_IDLE_TIME 60 min
SESSION_BROW_CLOSE_TIME 15 min
 */
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            statuscode = event?.body?.statuscode;
            if (event.headers.get('x-fcg-access-token')) {
              if (
                shouldUpdateSessionToken(
                  event.headers.get('x-fcg-access-token')
                )
              ) {
                console.log(
                  'interceptor updating session token with value: ',
                  event.headers.get('x-fcg-access-token')
                );
              }
              this.userService.user.sessiontoken =
                event.headers.get('x-fcg-access-token');
            }
            const responseUrl = event.url || '';
            if (isSamePageOrRng(responseUrl, rngDomain)) {
              storeAuthHeadersInSessionStorage(event.headers);
            }
          }
          status = 'success';
        },
        (error) => {
          statuscode = error?.error?.statuscode;
          status = error.status;
        }
      ),
      finalize(() => {
        if (status !== 'success') {
          //console.log('InterCeptor Finalize');
          //console.log(this);
          //console.log(this.errorPopupService);
          if (
            !no_generic_error_routes.some((err_route) =>
              request.url.includes(err_route)
            )
          ) {
            console.log(statuscode);
            this.errorPopupService.showPopup({
              httpCode: status as unknown as number,
              additionalErrorCode: statuscode
                ? `0x${statuscode?.toString()}`
                : '',
            });
          }
        }
      })
      /*map((event: HttpEvent<any>) => {
         if (event instanceof HttpResponse) {
         //console.log('event--->>>', event);
         }
         return event;
         }),
         catchError(error => {
         // if (error instanceof HttpErrorResponse) {

         // }
         throwError(error);

         })*/
    );
  }
}

function shouldUpdateSessionToken(accessToken: string): boolean {
  const currentAccessToken = sessionStorage.getItem('x-fcg-access-token');
  return currentAccessToken !== accessToken;
}

function isSamePageOrRng(httpUrl: string, rngDomain: string) {
  let httpDomain = '';
  try {
    httpDomain = new URL(httpUrl).hostname;
  } catch (error) {
    return true;
  }

  const isSamePage = httpDomain === window.location.hostname;
  if (!rngDomain) {
    return isSamePage;
  }
  const isFromRNG = httpDomain === rngDomain;
  return isSamePage || isFromRNG;
}

export function storeAuthHeadersInSessionStorage(headers: HttpHeaders): void {
  headers
    .keys()
    .filter((k) => k.startsWith('x-fcg-'))
    .forEach((xItem) => {
      if (headers.get(xItem)) {
        sessionStorage.setItem(xItem, headers.get(xItem));
      }
    });
}

export function getAuthHeaders(): Record<string, string> {
  const xFcgKeys = Object.keys(sessionStorage).filter((k) =>
    k.startsWith('x-fcg-')
  );
  return xFcgKeys.reduce((headers, key) => {
    const val = sessionStorage.getItem(key);
    if (val !== null && val !== 'null' && val !== '') {
      headers[key] = val;
    }
    return headers;
  }, {});
}

const no_generic_error_routes = [
  'signin/viewer/mobile/mobileChecklogin.jsp',
  'cgibin/PartnerFCGRNGGameServlet/environmentdescription',
];
