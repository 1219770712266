import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { PageConstant } from './constant/page.constant';
import { LanguageConstant } from './constant/language.constant';
import { UtilService } from './util/UtilService';

import { UserService } from './services/user.service';
import { GIInfoService } from './services/giinfo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Full Color Games';
  translateCurrentLang;
  appLanguage;
  isPortrait = false;
  _appSubscriptionScale;
  constructor(
    public translate: TranslateService,
    public router: Router,
    public utilService: UtilService,
    public userService: UserService,
    public giInfoService: GIInfoService
  ) {
    //console.log('App Constructor');
    const params: any = this.getQueryStrings();
    /* if(params.t == undefined && environment.envName !== 'serve') {
    window.location.replace('/index.jsp');
  } */
    if (params.puiForceClose) {
      window.close();
    }

    if (params.lang) {
      translate.setDefaultLang(params.lang);
      this.translate.use(params.lang);
      this.appLanguage = params.lang;
    } else {
      translate.setDefaultLang(LanguageConstant.ENGLISH);
    }

    this._appSubscriptionScale = utilService.scaleChange.subscribe((value) => {
      this.checkSizeAndShowPreloader();
    });
    this.checkSizeAndShowPreloader();
    if (Object.keys(params).length !== 0) {
      console.log(
        'app.component 1 updating session token with value: ',
        params.sessiontoken
      );
      if (params.sessiontoken) {
        const storageAccessToken = sessionStorage.getItem('x-fcg-access-token');
        this.userService.user.sessiontoken =
          storageAccessToken || params.sessiontoken;
      }

      if (params.flylogin != undefined && params.flylogin != null) {
        this.userService.user.isFlyLogin = params.flylogin;
      }
      if (params.reloadURL != undefined && params.reloadURL != null) {
        this.userService.user.reloadURL = params.reloadURL;
      }
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateCurrentLang = translate.currentLang;
    });
    this.giInfoService.getEnvironmentData().subscribe((response) => {
      this.utilService.gameEnvironment = response.environmentdetail;
      //console.log('Environment: ' + this.utilService.gameEnvironment);
    });

    this.init();
  }

  checkSizeAndShowPreloader = () => {
    if (window.innerWidth >= window.innerHeight) {
      this.isPortrait = false;
    } else {
      this.isPortrait = true;
    }
  };
  init = () => {
    const params: any = this.getQueryStrings();

    if (Object.keys(params).length !== 0) {
      if (params.homeurl) {
        sessionStorage.setItem('homeurl', params.homeurl);
      }

      if (params.sessiontoken) {
        console.log(
          'app.component 2 updating session token with value: ',
          params.sessiontoken
        );
        const storageAccessToken = sessionStorage.getItem('x-fcg-access-token');
        this.userService.user.sessiontoken =
          storageAccessToken || params.sessiontoken;
      }
      if (params.lang) {
        this.translate.use(params.lang);
        this.utilService.isUpdateLanguage = true;
        this.utilService.currentLanguage = params.lang;
        params.currentLanguage = params.lang;
        this.appLanguage = params.lang;
      }
      if (params['initialusertype'] !== undefined) {
        if (
          params.initialusertype != undefined &&
          params.initialusertype != null &&
          params.initialusertype != 'undefined'
        ) {
          this.userService.user.appusertype = params.initialusertype;
        } else {
          this.userService.user.appusertype = undefined;
        }
      }
      if (
        params.flylogin != undefined &&
        params.flylogin != null &&
        params.flylogin != 'undefined'
      ) {
        this.userService.user.isFlyLogin =
          params.flylogin == 'true' ? true : false;
      }

      if (params.currentPage) {
        if (params.currentPage === 'reports') {
          const loc_params: any = Object.assign({}, params);
          loc_params.initiatePage = 'reports';
          this.pageNavigate(PageConstant.HISTORYDETAIL, loc_params);
        } else if (params.currentPage === 'game') {
          params.initiatePage = 'game';
          this.utilService.isLoadedFromGame = true;
          if (this.utilService.isDesktop) {
            this.pageNavigate(PageConstant.HISTORYDETAIL, params);
          } else {
            this.pageNavigate(PageConstant.HISTORYDETAIL, params);
          }
        } else {
          if (
            params.currentPage == 'index' ||
            params.currentPage == 'indexmobile'
          ) {
            this.utilService.showAppPreloader = false;
          }
          if (params.currentPage == 'error') {
            this.utilService.showAppPreloader = false;
          }
          this.pageNavigate(params.currentPage, params);
        }
      } else {
        this.utilService.showAppPreloader = false;
        this.pageNavigate(PageConstant.INDEX);
      }
    } else {
      this.utilService.showAppPreloader = false;
      this.pageNavigate(PageConstant.INDEX);
    }
  };

  pageNavigate(page: PageConstant, params: any = {}) {
    // console.log('pageNavigate');
    // console.log(page);
    // console.log(params);
    params.currentPage = page;
    this.router.navigate(['/' + page], {
      queryParams: params,
    });
  }

  getQueryStrings() {
    const assoc = {};
    const queryString = location.search.substring(1);
    const keyValues = queryString.split('&');
    for (const i in keyValues) {
      if (keyValues.hasOwnProperty(i)) {
        const key = keyValues[i].split('=');
        if (key.length > 1) {
          assoc[this.decode(key[0])] = this.decode(key[1]);
        }
      }
    }
    return assoc;
  }

  decode(s) {
    return decodeURIComponent(s.replace(/\+/g, ' '));
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    if (this._appSubscriptionScale) {
      this._appSubscriptionScale.unsubscribe();
    }
    delete this._appSubscriptionScale;
    delete this.userService.user.balance;
    delete this.userService.user.casinoId;
    delete this.userService.user.currencyCode;
    delete this.userService.user.sessionId;
    delete this.userService.user.status;
    delete this.userService.user.tables;
    delete this.userService.user.userId;
    delete this.userService.user.sessiontoken;
    delete this.userService.user.funUserBalance;
    delete this.userService.user.fun_user_exist;
    delete this.userService.user.usertypecode;
    delete this.userService.user.fun_user_game_pending;
    delete this.userService.user.fun_user_game_pending_checked;
    delete this.userService.user.gameminbet;
    delete this.userService.user.isAutoFullScreenDone;
    delete this.userService.user.initialusertype;
    delete this.userService.user.appusertype;
    delete this.userService.user.isFlyLogin;
    delete this.userService.user.isGameStarted;
    delete this.userService.user.secretSquirrelKey;
    delete this.title;
    delete this.translateCurrentLang;
    delete this.appLanguage;
    delete this.isPortrait;
    delete this._appSubscriptionScale;
  }
}
