import { Inject, Injectable } from '@angular/core';
import { API } from '../constant/api';
import { $http } from '../util/HttpService';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UtilService } from '../util/UtilService';
import { UserService } from './user.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    @Inject($http) private http: $http,
    public utilService: UtilService,
    public userService: UserService
  ) {}

  mobileCheckLogin(data): Observable<string> {
    return this.http
      .post(API.MOBILECHECKLOGIN, data, undefined, undefined, undefined, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<MobileCheckLogin>) => {
          if (response.body.status === LoginStatus.success) {
            console.log(
              'logging service updating session token with value: ',
              response.body.sessiontoken
            );
            this.userService.user.sessiontoken = response.body.sessiontoken;
          }
          this.storeAuthHeadersInSessionStorage(response.headers);
          return response.body.status;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  private storeAuthHeadersInSessionStorage(headers: HttpHeaders): void {
    headers
      .keys()
      .filter((k) => k.startsWith('x-fcg-'))
      .forEach((xItem) => {
        if (headers.get(xItem)) {
          sessionStorage.setItem(xItem, headers.get(xItem));
        }
      });
  }

  verifyEmail(data): Observable<any> {
    return this.http.post(API.EMAILVERIFICATION, data, false, 'text').pipe(
      map((response: any) => {
        return this.utilService.querystringToJSON(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}

export interface MobileCheckLogin {
  balance: number;
  casinoId: string;
  currencyCode: string;
  sessionId: string;
  sessiontoken: string;
  status: LoginStatus;
  tables: any[];
  userId: string;
}

export enum LoginStatus {
  success = 'success',
}
